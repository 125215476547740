import "./settings.mjs";
import { isMobile } from "@pixi/settings";
import { default as default2 } from "eventemitter3";
import { default as default3 } from "earcut";
import { url } from "./url.mjs";
import { path } from "./path.mjs";
import { detectVideoAlphaMode } from "./browser/detectVideoAlphaMode.mjs";
import { sayHello, skipHello } from "./browser/hello.mjs";
import { isWebGLSupported } from "./browser/isWebGLSupported.mjs";
import { hex2rgb, hex2string, rgb2hex, string2hex } from "./color/hex.mjs";
import { correctBlendMode, premultiplyBlendMode, premultiplyRgba, premultiplyTint, premultiplyTintToRgba } from "./color/premultiply.mjs";
import { DATA_URI } from "./const.mjs";
import { createIndicesForQuads } from "./data/createIndicesForQuads.mjs";
import { getBufferType } from "./data/getBufferType.mjs";
import { interleaveTypedArrays } from "./data/interleaveTypedArrays.mjs";
import { isPow2, log2, nextPow2 } from "./data/pow2.mjs";
import { removeItems } from "./data/removeItems.mjs";
import { sign } from "./data/sign.mjs";
import { uid } from "./data/uid.mjs";
import { deprecation } from "./logging/deprecation.mjs";
import { BoundingBox } from "./media/BoundingBox.mjs";
import { BaseTextureCache, ProgramCache, TextureCache, clearTextureCache, destroyTextureCache } from "./media/caches.mjs";
import { CanvasRenderTarget } from "./media/CanvasRenderTarget.mjs";
import { getCanvasBoundingBox } from "./media/getCanvasBoundingBox.mjs";
import { trimCanvas } from "./media/trimCanvas.mjs";
import { decomposeDataUri } from "./network/decomposeDataUri.mjs";
import { determineCrossOrigin } from "./network/determineCrossOrigin.mjs";
import { getResolutionOfUrl } from "./network/getResolutionOfUrl.mjs";
import "./types/index.mjs";
export { BaseTextureCache, BoundingBox, CanvasRenderTarget, DATA_URI, default2 as EventEmitter, ProgramCache, TextureCache, clearTextureCache, correctBlendMode, createIndicesForQuads, decomposeDataUri, deprecation, destroyTextureCache, detectVideoAlphaMode, determineCrossOrigin, default3 as earcut, getBufferType, getCanvasBoundingBox, getResolutionOfUrl, hex2rgb, hex2string, interleaveTypedArrays, isMobile, isPow2, isWebGLSupported, log2, nextPow2, path, premultiplyBlendMode, premultiplyRgba, premultiplyTint, premultiplyTintToRgba, removeItems, rgb2hex, sayHello, sign, skipHello, string2hex, trimCanvas, uid, url };
