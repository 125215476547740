<div class="row m-0 p-0 w-100">
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>樂曲適性</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>キュート</td>
          <td>{{ this.getAttribute("cute") }}</td>
        </tr>
        <tr>
          <td i18n>クール</td>
          <td>{{ this.getAttribute("cool") }}</td>
        </tr>
        <tr>
          <td i18n>ポジティブ</td>
          <td>{{ this.getAttribute("positive") }}</td>
        </tr>
        <tr>
          <td i18n>リラックス</td>
          <td>{{ this.getAttribute("relax") }}</td>
        </tr>
        <tr>
          <td i18n>エモーショナル</td>
          <td>{{ this.getAttribute("emotional") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>表現適性</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>ハイペース</td>
          <td>{{ this.getAttribute("high") }}</td>
        </tr>
        <tr>
          <td i18n>ミドルペース</td>
          <td>{{ this.getAttribute("medium") }}</td>
        </tr>
        <tr>
          <td i18n>スローペース</td>
          <td>{{ this.getAttribute("slow") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>環境適性</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>快晴</td>
          <td>{{ this.getAttribute("fine") }}</td>
        </tr>
        <tr>
          <td i18n>晴れ</td>
          <td>{{ this.getAttribute("sunny") }}</td>
        </tr>
        <tr>
          <td i18n>くもり</td>
          <td>{{ this.getAttribute("cloudy") }}</td>
        </tr>
        <tr>
          <td i18n>雨</td>
          <td>{{ this.getAttribute("rain") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>