let unsafeEval;
function unsafeEvalSupported() {
  if (typeof unsafeEval == "boolean") return unsafeEval;
  try {
    unsafeEval = new Function("param1", "param2", "param3", "return param1[param2] === param3;")({
      a: "b"
    }, "a", "b") === !0;
  } catch {
    unsafeEval = !1;
  }
  return unsafeEval;
}
export { unsafeEvalSupported };
