<div class="container-fluid h-100 pe-0">
  <div class="row h-100 p-0 overflow-hidden">
    <app-sidebar
      class="d-block d-md-none col-12 bg-light overflow-auto"
      charaListId="charaList0"></app-sidebar>
    <app-sidebar
      class="d-none d-md-block col-lg-2 col-md-4 bg-secondary p-2 h-100 overflow-auto d-flex"
      [showSideBar]="true"
      [isBigScreen]="true"
      charaListId="charaList1">
    </app-sidebar>

    <router-outlet class="d-none"></router-outlet>
  </div>
</div>
