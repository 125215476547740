@if (this.idolInfo && this.album) {
<div class="row m-0 p-0">
  <div class="d-flex col-lg-4 col-md-12 h-100 container justify-content-center" id="divIdolData">
    <div class="row">
      <div class="col-12 pt-2 d-block">
        <h1 class=" w-100 d-none d-md-block text-center">{{ this.idolInfo.idolName }}</h1>
        <img
          [src]="this.scUrlService.getIdolIcon(this.idolInfo.idolId)"
          [alt]="'Icon of ' + this.idolInfo.idolName"
          class="img-fluid mx-auto d-block"
          width="400"
          height="400" />
      </div>
      <div class="col-12 d-block">
        <table class="table w-100">
          <tbody>
            <tr class="row">
              <th class="col-2"></th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.hiragana }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>姓名</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.idolName }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>色碼</th>
              <td class="col-10 d-flex align-items-center" [ngStyle]="{color: this.idolInfo.color1}">■ {{ this.idolInfo.color2 }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>所屬</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.unit.unitName }}</td>
            </tr>
            <tr class="row">
              <th class="col-2 d-flex align-items-center" i18n>年齡</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.age }}</td>
              <th class="col-2 d-flex align-items-center border-start" i18n>血型</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.bloodType }}</td>
            </tr>
            <tr class="row">
              <th class="col-2 d-flex align-items-center" i18n>生日</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.birthday }}</td>
              <th class="col-2 d-flex align-items-center border-start" i18n>星座</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.starSign }}</td>
            </tr>
            <tr class="row">
              <th class="col-2 d-flex align-items-center" i18n>身高</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.height }}</td>
              <th class="col-2 d-flex align-items-center border-start" i18n>體重</th>
              <td class="col-4 d-flex align-items-center">{{ this.idolInfo.weight }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>三圍</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.threeSize }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>出身</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.birthPlace }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>興趣</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.interest }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>特技</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.specialSkill }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>聲優</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.preCv ? this.idolInfo.preCv + " → " : '' }}{{ this.idolInfo.cv }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex col-lg-8 col-md-12 min-vh-100 container justify-content-center pt-3 pb-5" id="divInfo">
    <div class="row w-100">
      <div class="card col-12 w-100 overflow-auto ps-0 pe-0 h-100">
        <div class="card-header w-100">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a href="javascript: void(0);" class="nav-link" (click)="tabProduce()" [ngClass]="{'disabled': isTabProduce(), 'active': isTabProduce()}" style="font-family: Meiryo" i18n>P卡列表</a>
            </li>
            <li class="nav-item">
              <a href="javascript: void(0);" class="nav-link" (click)="tabSupport()" [ngClass]="{'disabled': isTabSupport(), 'active': isTabSupport()}" style="font-family: Meiryo" i18n>S卡列表</a>
            </li>
            <li class="nav-item">
              <a href="javascript: void(0);" class="nav-link" (click)="tabEvent()" [ngClass]="{'disabled': isTabEvent(), 'active': isTabEvent()}" style="font-family: Meiryo" i18n>劇情列表</a>
            </li>
          </ul>
        </div>
        <div class="card-body w-100 container overflow-auto h-100 justify-content-center">
          <div class="row w-100 m-0" [ngClass]="{'d-none': !isTabProduce()}">
            @if (this.pSSR.length || this.pSR.length || this.pR.length) {
            @if (this.pSSR.length) {
            <h5 class="text-start pt-2">P_SSR</h5>
            }
            @for (card of this.pSSR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            @if (this.pSR.length) {
            <h5 class="text-start pt-2">P_SR</h5>
            }
            @for (card of this.pSR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            @if (this.pR.length) {
            <h5 class="text-start pt-2">P_R</h5>
            }
            @for (card of this.pR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            }
            @if (!this.pSSR.length && !this.pSR.length && !this.pR.length) {
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="d-flex ">
                <div class="display-4" i18n>なし</div>
              </div>
            </div>
            }
          </div>
          <div class="row w-100 m-0" [ngClass]="{'d-none': !isTabSupport()}">
            @if (this.sSSR.length || this.sSR.length || this.sR.length || this.sN.length) {
            @if (this.sSSR.length) {
            <h5 class="text-start pt-2">S_SSR</h5>
            }
            @for (card of this.sSSR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            @if (this.sSR.length) {
            <h5 class="text-start pt-2">S_SR</h5>
            }
            @for (card of this.sSR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            @if (this.sR.length) {
            <h5 class="text-start pt-2">S_R</h5>
            }
            @for (card of this.sR; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            @if (this.sN.length) {
            <h5 class="text-start pt-2">S_N</h5>
            }
            @for (card of this.sN; track card) {
            <app-card-item [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            }
            }
            @if (!this.sSSR.length && !this.sSR.length && !this.sR.length && !this.sN.length) {
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="d-flex ">
                <div class="display-4" i18n>なし</div>
              </div>
            </div>
            }
          </div>
          <div class="row w-100 m-0" [ngClass]="{'d-none': !isTabEvent()}">
            <div ngbAccordion class="bg-light p-0">
              @for (k of this.getEventTitles(); track k) {
              @if (this.getEventsByType(k.albumType).length != 0) {
              <div ngbAccordionItem>
                <h3 ngbAccordionHeader>
                  <button ngbAccordionButton>{{k.title}}</button>
                </h3>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <table class="table table-hover table-bordered m-0">
                      <thead></thead>
                      <tbody>
                        @for (e of this.getEventsByType(k.albumType); track $index) {
                        @if (e) {
                        <tr>
                          <td>
                            <a [href]="this.getEventViewerUrl(e)" target="_blank">
                              <div class="d-inline-block p-1">
                                <svg class="align-self-center" fill="#3880ff" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-3 -3 66.00 66.00" xml:space="preserve" stroke="#3880ff" stroke-width="4.5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path
                                        d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"></path> <path
                                        d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"></path> </g> </g></svg>
                              </div>
                              <div class="d-inline-block align-middle">
                                {{e.title}}
                              </div>
                            </a>
                          </td>
                        </tr>
                        }}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              }}
            </div>
            @if (!this.album.communications.length) {
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="d-flex ">
                <div class="display-4" i18n>なし</div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}
