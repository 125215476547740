import { ALPHA_MODES } from "@pixi/constants";
import { determineCrossOrigin } from "@pixi/utils";
import { Resource } from "./Resource.mjs";
class BaseImageResource extends Resource {
  /**
   * @param {PIXI.ImageSourcee} source
   */
  constructor(source) {
    const sourceAny = source,
      width = sourceAny.naturalWidth || sourceAny.videoWidth || sourceAny.displayWidth || sourceAny.width,
      height = sourceAny.naturalHeight || sourceAny.videoHeight || sourceAny.displayHeight || sourceAny.height;
    super(width, height), this.source = source, this.noSubImage = !1;
  }
  /**
   * Set cross origin based detecting the url and the crossorigin
   * @param element - Element to apply crossOrigin
   * @param url - URL to check
   * @param crossorigin - Cross origin value to use
   */
  static crossOrigin(element, url, crossorigin) {
    crossorigin === void 0 && !url.startsWith("data:") ? element.crossOrigin = determineCrossOrigin(url) : crossorigin !== !1 && (element.crossOrigin = typeof crossorigin == "string" ? crossorigin : "anonymous");
  }
  /**
   * Upload the texture to the GPU.
   * @param renderer - Upload to the renderer
   * @param baseTexture - Reference to parent texture
   * @param glTexture
   * @param {PIXI.ImageSourcee} [source] - (optional)
   * @returns - true is success
   */
  upload(renderer, baseTexture, glTexture, source) {
    const gl = renderer.gl,
      width = baseTexture.realWidth,
      height = baseTexture.realHeight;
    if (source = source || this.source, typeof HTMLImageElement < "u" && source instanceof HTMLImageElement) {
      if (!source.complete || source.naturalWidth === 0) return !1;
    } else if (typeof HTMLVideoElement < "u" && source instanceof HTMLVideoElement && source.readyState <= 1) return !1;
    return gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, baseTexture.alphaMode === ALPHA_MODES.UNPACK), !this.noSubImage && baseTexture.target === gl.TEXTURE_2D && glTexture.width === width && glTexture.height === height ? gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, baseTexture.format, glTexture.type, source) : (glTexture.width = width, glTexture.height = height, gl.texImage2D(baseTexture.target, 0, glTexture.internalFormat, baseTexture.format, glTexture.type, source)), !0;
  }
  /**
   * Checks if source width/height was changed, resize can cause extra baseTexture update.
   * Triggers one update in any case.
   */
  update() {
    if (this.destroyed) return;
    const source = this.source,
      width = source.naturalWidth || source.videoWidth || source.width,
      height = source.naturalHeight || source.videoHeight || source.height;
    this.resize(width, height), super.update();
  }
  /** Destroy this {@link PIXI.BaseImageResource} */
  dispose() {
    this.source = null;
  }
}
export { BaseImageResource };
