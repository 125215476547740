import { BLEND_MODES } from "@pixi/constants";
import { ExtensionType, extensions } from "@pixi/extensions";
import { State } from "./State.mjs";
import { mapWebGLBlendModesToPixi } from "./utils/mapWebGLBlendModesToPixi.mjs";
const BLEND = 0,
  OFFSET = 1,
  CULLING = 2,
  DEPTH_TEST = 3,
  WINDING = 4,
  DEPTH_MASK = 5,
  _StateSystem = class _StateSystem2 {
    constructor() {
      this.gl = null, this.stateId = 0, this.polygonOffset = 0, this.blendMode = BLEND_MODES.NONE, this._blendEq = !1, this.map = [], this.map[BLEND] = this.setBlend, this.map[OFFSET] = this.setOffset, this.map[CULLING] = this.setCullFace, this.map[DEPTH_TEST] = this.setDepthTest, this.map[WINDING] = this.setFrontFace, this.map[DEPTH_MASK] = this.setDepthMask, this.checks = [], this.defaultState = new State(), this.defaultState.blend = !0;
    }
    contextChange(gl) {
      this.gl = gl, this.blendModes = mapWebGLBlendModesToPixi(gl), this.set(this.defaultState), this.reset();
    }
    /**
     * Sets the current state
     * @param {*} state - The state to set.
     */
    set(state) {
      if (state = state || this.defaultState, this.stateId !== state.data) {
        let diff = this.stateId ^ state.data,
          i = 0;
        for (; diff;) diff & 1 && this.map[i].call(this, !!(state.data & 1 << i)), diff = diff >> 1, i++;
        this.stateId = state.data;
      }
      for (let i = 0; i < this.checks.length; i++) this.checks[i](this, state);
    }
    /**
     * Sets the state, when previous state is unknown.
     * @param {*} state - The state to set
     */
    forceState(state) {
      state = state || this.defaultState;
      for (let i = 0; i < this.map.length; i++) this.map[i].call(this, !!(state.data & 1 << i));
      for (let i = 0; i < this.checks.length; i++) this.checks[i](this, state);
      this.stateId = state.data;
    }
    /**
     * Sets whether to enable or disable blending.
     * @param value - Turn on or off WebGl blending.
     */
    setBlend(value) {
      this.updateCheck(_StateSystem2.checkBlendMode, value), this.gl[value ? "enable" : "disable"](this.gl.BLEND);
    }
    /**
     * Sets whether to enable or disable polygon offset fill.
     * @param value - Turn on or off webgl polygon offset testing.
     */
    setOffset(value) {
      this.updateCheck(_StateSystem2.checkPolygonOffset, value), this.gl[value ? "enable" : "disable"](this.gl.POLYGON_OFFSET_FILL);
    }
    /**
     * Sets whether to enable or disable depth test.
     * @param value - Turn on or off webgl depth testing.
     */
    setDepthTest(value) {
      this.gl[value ? "enable" : "disable"](this.gl.DEPTH_TEST);
    }
    /**
     * Sets whether to enable or disable depth mask.
     * @param value - Turn on or off webgl depth mask.
     */
    setDepthMask(value) {
      this.gl.depthMask(value);
    }
    /**
     * Sets whether to enable or disable cull face.
     * @param {boolean} value - Turn on or off webgl cull face.
     */
    setCullFace(value) {
      this.gl[value ? "enable" : "disable"](this.gl.CULL_FACE);
    }
    /**
     * Sets the gl front face.
     * @param {boolean} value - true is clockwise and false is counter-clockwise
     */
    setFrontFace(value) {
      this.gl.frontFace(this.gl[value ? "CW" : "CCW"]);
    }
    /**
     * Sets the blend mode.
     * @param {number} value - The blend mode to set to.
     */
    setBlendMode(value) {
      if (value === this.blendMode) return;
      this.blendMode = value;
      const mode = this.blendModes[value],
        gl = this.gl;
      mode.length === 2 ? gl.blendFunc(mode[0], mode[1]) : gl.blendFuncSeparate(mode[0], mode[1], mode[2], mode[3]), mode.length === 6 ? (this._blendEq = !0, gl.blendEquationSeparate(mode[4], mode[5])) : this._blendEq && (this._blendEq = !1, gl.blendEquationSeparate(gl.FUNC_ADD, gl.FUNC_ADD));
    }
    /**
     * Sets the polygon offset.
     * @param {number} value - the polygon offset
     * @param {number} scale - the polygon offset scale
     */
    setPolygonOffset(value, scale) {
      this.gl.polygonOffset(value, scale);
    }
    // used
    /** Resets all the logic and disables the VAOs. */
    reset() {
      this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, !1), this.forceState(this.defaultState), this._blendEq = !0, this.blendMode = -1, this.setBlendMode(0);
    }
    /**
     * Checks to see which updates should be checked based on which settings have been activated.
     *
     * For example, if blend is enabled then we should check the blend modes each time the state is changed
     * or if polygon fill is activated then we need to check if the polygon offset changes.
     * The idea is that we only check what we have too.
     * @param func - the checking function to add or remove
     * @param value - should the check function be added or removed.
     */
    updateCheck(func, value) {
      const index = this.checks.indexOf(func);
      value && index === -1 ? this.checks.push(func) : !value && index !== -1 && this.checks.splice(index, 1);
    }
    /**
     * A private little wrapper function that we call to check the blend mode.
     * @param system - the System to perform the state check on
     * @param state - the state that the blendMode will pulled from
     */
    static checkBlendMode(system, state) {
      system.setBlendMode(state.blendMode);
    }
    /**
     * A private little wrapper function that we call to check the polygon offset.
     * @param system - the System to perform the state check on
     * @param state - the state that the blendMode will pulled from
     */
    static checkPolygonOffset(system, state) {
      system.setPolygonOffset(1, state.polygonOffset);
    }
    /**
     * @ignore
     */
    destroy() {
      this.gl = null;
    }
  };
_StateSystem.extension = {
  type: ExtensionType.RendererSystem,
  name: "state"
};
let StateSystem = _StateSystem;
extensions.add(StateSystem);
export { StateSystem };
