<div class="row overflow-auto w-100 h-100">
  <div class="col-lg-3 container overflow-auto">
    <div class="row d-flex">
      <h2 class="col m-2" style="font-family: Meiryo" i18n>最近更新</h2>
    </div>
  </div>
  <div class="col-lg-9 container overflow-auto">
    <div class="row d-flex">
      <h2 class="col m-2" style="font-family: Meiryo" i18n>最新卡面</h2>
    </div>
    <div class="row d-flex">
      @if (this.latestP) {
      <div class="p-0 pb-2">
        <div class="card ps-0 pe-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li class="nav-item">
                <a
                  href="javascript: void(0);"
                  class="nav-link"
                  (click)="togglePS= true"
                  [ngClass]="{ disabled: togglePS, active: togglePS }"
                  style="font-family: Meiryo" i18n>P卡列表</a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript: void(0);"
                  class="nav-link"
                  (click)="togglePS= false"
                  [ngClass]="{ disabled: !togglePS, active: !togglePS }"
                  style="font-family: Meiryo" i18n>S卡列表</a>
              </li>
            </ul>
          </div>
          <div class="card-body d-flex h-auto overflow-auto pb-0">
            <div class="row" [ngClass]="{ 'd-none': !togglePS }">
              @for (card of this.latestP; track card) {
              <app-card-item
                [cardInfo]="card"
                [pathType]="'/pcardinfo'"
                [isHome]="true"
                class="col-md-12 col-lg-4 col-sm-12 p-1"></app-card-item>
              }
            </div>
            <div class="row" [ngClass]="{ 'd-none': togglePS }">
              @for (card of this.latestS; track card) {
              <app-card-item
                [cardInfo]="card"
                [pathType]="'/scardinfo'"
                [isHome]="true"
                class="col-md-12 col-lg-4 col-sm-12 p-1"></app-card-item>
              }
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</div>
