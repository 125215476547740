import { ExtensionType, extensions } from "@pixi/core";
const assetKeyMap = {
  loader: ExtensionType.LoadParser,
  resolver: ExtensionType.ResolveParser,
  cache: ExtensionType.CacheParser,
  detection: ExtensionType.DetectionParser
};
extensions.handle(ExtensionType.Asset, extension => {
  const ref = extension.ref;
  Object.entries(assetKeyMap).filter(([key]) => !!ref[key]).forEach(([key, type]) => extensions.add(Object.assign(ref[key],
  // Allow the function to optionally define it's own
  // ExtensionMetadata, the use cases here is priority for LoaderParsers
  {
    extension: ref[key].extension ?? type
  })));
}, extension => {
  const ref = extension.ref;
  Object.keys(assetKeyMap).filter(key => !!ref[key]).forEach(key => extensions.remove(ref[key]));
});
