<h3 class="col-12 pt-2">
  ステータス
</h3>
<table class="table text-center">
  <thead>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th class="color_vocal">Vo</th>
      <td>{{this.cardStatus.vocal}}</td>
      <th class="color_dance">Da</th>
      <td>{{this.cardStatus.dance}}</td>
    </tr>
    <tr>
      <th class="color_visual">Vi</th>
      <td>{{this.cardStatus.visual}}</td>
      <th class="color_mental">Me</th>
      <td>{{this.cardStatus.mental}}</td>
    </tr>
  </tbody>
</table>