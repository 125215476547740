<div class="col-lg-6 col-md-12">
  <table class="table table-hover">
    <thead>
      <tr>
        <th i18n>Produce</th>
        <th i18n>實裝日</th>
        <th i18n>距今(天)</th>
      </tr>
    </thead>
    <tbody>
      @for (card of this.timetable.produce; track card) {
      <tr>
        <td>
          <a
            [routerLink]="'/pcardinfo'"
            [queryParams]="{uuid: card.cardUuid}">
            {{ card.cardName }}
          </a>
        </td>
        <td>{{ card.releaseDate }}</td>
        <td>{{ this.timeDuration(card.releaseDate) }}</td>
      </tr>
      }
    </tbody>
  </table>
</div>
<div class="col-lg-6 col-md-12">
  <table class="table table-hover">
    <thead>
      <tr>
        <th i18n>Support</th>
        <th i18n>實裝日</th>
        <th i18n>距今(天)</th>
      </tr>
    </thead>
    <tbody>
      @for (card of this.timetable.support; track card) {
      <tr>
        <td>
          <a
            [routerLink]="'/scardinfo'"
            [queryParams]="{uuid: card.cardUuid}">
            {{ card.cardName }}
          </a>
        </td>
        <td>{{ card.releaseDate }}</td>
        <td>{{ this.timeDuration(card.releaseDate) }}</td>
      </tr>
      }
    </tbody>
  </table>
</div>
