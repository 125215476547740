<h1 class="w-100 display-5 text-center m-0 d-sm-none d-md-block" #cardleTitle>
  <strong>ShinyColors Cardle</strong>
</h1>
<div class="row p-0 m-0 justify-content-center">
  <div class="col-sm-3 d-xs-none d-sm-block"></div>
  <div class="col-sm-6 text-center row" #canvasDiv>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice1></canvas>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice2></canvas>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice3></canvas>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice4></canvas>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice5></canvas>
    <canvas width="100px" height="100px" class="col-md-4 col-sm-6 rounded p-1" #slice6></canvas>
  </div>
  <div class="col-sm-3 d-xs-none d-sm-block"></div>
</div>
<div class="justify-content-center d-flex pt-2">
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
  <div class="rounded d-inline-block m-1 generalBlock guessBlock" #resultDiv></div>
</div>
<div class="row m-2 justify-content-center">
  <div class="col-sm-3 d-xs-none d-sm-block"></div>
  <div class="col-sm-6 row text-center">
    <select class="w-50 form-select" #idolList></select>
    <select class="w-50 form-select" #cardList></select>
  </div>
  <div class="col-sm-3 d-xs-none d-sm-block"></div>

</div>
<div class="justify-content-center d-flex m-2 pb-5">
  <button class="btn btn-primary m-1" #skipBtn>Skip </button>
  <button class="btn btn-primary m-1" #guessBtn>Guess</button>
</div>

<ng-template #resultModal let-modal>
  <div class="modal-header">
    <h1>{{this?.cardleInfo!.cardleDate | date: 'yyyy/MM/dd'}} #{{this.cardleInfo.cardleIndex}}</h1>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body justify-content-center">
    <span>Next Cardle in {{this.cardleInfo.cardleDate }}</span>
    <img src={{this.imgUri}} [alt]="'Image of ' + this.cardleInfo.enza.cardName" class="img-fluid">
    <span class="text-center">{{this.cardleInfo.enza.cardName}}</span>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="this.shareTwitter()">
      <span>Tweet</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
      </svg>
    </button>
    <button class="btn btn-info" (click)="this.shareClipboard()">
      <span>Share</span>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" fill="currentColor" viewBox="0 0 24 24">
        <path fill="var(#fff)"
          d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
      </svg>
    </button>
  </div>
</ng-template>