@for (unit of Units; track unit; let i = $index) {
<div>
  <button
    class="btn w-100 text-start rounded-0"
    [ngClass]="{ 'rounded-top': i == 0, 'rounded-bottom': i == Units.length - 1}"
    [style]="'background-color:' + unit.color1 + ';'"
    (click)="unitCollapse.toggle()"
    [attr.aria-expanded]="!this.getCollapseStatus(unit.unitId)">
    <img
      [src]="this.scUrlService.getUnitIconUri(unit.unitId)"
      width="20"
      height="20"
      [alt]="'Icon of unit ' + unit.unitName" />
    {{ unit.unitHiragana }}
  </button>
  <div
    class="list-group"
    [id]="'unit' + unit.unitId"
    [ngbCollapse]="this.getCollapseStatus(unit.unitId)"
    #unitCollapse="ngbCollapse">
    @for (idol of unit.idols; track idol) {
    <a
      class="ps-4 list-group-item-action list-group-item"
      [ngClass]="{ active: this.currentIdolID === idol.idolId }"
      [routerLink]="['/idolinfo']"
      [queryParams]="{ idolid: idol.idolId }"
      (click)="this.onIdolClicked()">
      <img
        [src]="this.scUrlService.getIdolSmlIcon(idol.idolId)"
        [alt]="'Icon of idol ' + idol.idolName"
        width="40"
        height="40" />
      {{ idol.idolName }}
    </a>
    }
  </div>
</div>
}
